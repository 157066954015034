
// const environment = {
//   'apiUrl':'http://192.168.0.205:21040/api/',
//   'centralUrl':'http://192.168.0.205:21040/api/',
//   'socketIoUrl' : "http://192.168.0.205:21040",
//   'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTczMzc1MDUyMiwiZXhwIjoxNzY1Mjg2NTIyfQ.pbwX3uvZRmZ3Lb_HL9ZC3m54AfwLpjM6j806b7O8Cw0',
//   'timeoutValue': 20000
// }

const environment = {
  'centralUrl': 'https://starfish-app-gv2l6.ondigitalocean.app/api/', 
  'apiUrl': 'https://starfish-app-gv2l6.ondigitalocean.app/api/', 
  'socketIoUrl' : 'https://starfish-app-gv2l6.ondigitalocean.app',
  'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTczMzc1MDUyMiwiZXhwIjoxNzY1Mjg2NTIyfQ.pbwX3uvZRmZ3Lb_HL9ZC3m54AfwLpjM6j806b7O8Cw0',
 'timeoutValue': 20000
}


// const environment = {
//   'apiUrl':'http://localhost:21040/api/',
//   'socketIoUrl' : "http://localhost:21040",
//   'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTczMzc1MDUyMiwiZXhwIjoxNzY1Mjg2NTIyfQ.pbwX3uvZRmZ3Lb_HL9ZC3m54AfwLpjM6j806b7O8Cw0',
//   'timeoutValue': 20000
// }

// const environment = {
//   'apiUrl':'https://www.smartapplications.com.br/api/',
//   'socketIoUrl' : "http://www.smartapplications.com.br:21020",
//   'preUser' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJWYWxpZGF0ZWQiOiJPSyIsIlVzZXJJRCI6IjMyODZlYWUzLWM2ZTMtNGZjZS1iNjFlLTUwYTBhODk1OGYyOSIsIlVzZXJOYW1lIjoicHJldXNlciIsIlVzZXJFbWFpbCI6InByZXVzZXJAc21hcnRhcHBsaWNhdGlvbnMuY29tIiwiVXNlclJvbGUiOiJQcmVVc2VyIiwiSXNFbWFpbFZlcmlmaWVkIjoiMCIsImlhdCI6MTczMzc1MDUyMiwiZXhwIjoxNzY1Mjg2NTIyfQ.pbwX3uvZRmZ3Lb_HL9ZC3m54AfwLpjM6j806b7O8Cw0',
//   'timeoutValue': 20000
// }

export {environment};